<script setup lang="ts">
    import Chip from 'primevue/chip';
    import { useI18n } from 'vue-i18n';
    import { computed } from 'vue';

    const props = withDefaults(
        defineProps<{
            isUsed: boolean;
            isDiscounted: boolean;
            showChip?: boolean;
            showDiscountedChip?: boolean;
            discountReference?: string | null;
        }>(),
        {
            showChip: false,
            showDiscountedChip: true,
            discountReference: null,
        }
    );

    const { t } = useI18n();

    const discountReference = computed(() => {
        return props.discountReference ?? t('PRODUCT.STATE.OFFER');
    });
</script>

<template>
    <div class="chippedContent">
        <slot name="content"></slot>

        <div class="chips">
            <div v-if="showDiscountedChip && isDiscounted">
                <Chip :label="discountReference" class="discountedChip"></Chip>
            </div>

            <div v-if="showChip && isUsed">
                <Chip :label="t('PRODUCT.STATE.USED')" class="usedChip"></Chip>
            </div>
            <div v-else-if="showChip">
                <Chip :label="t('PRODUCT.STATE.NEW')" class="newChip"></Chip>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as main;

    .chippedContent {
        position: relative;
        display: inline-block;

        .p-chip {
            border-radius: main.$spacing-2;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .chips {
        position: absolute;
        display: flex;
        top: main.$spacing-4;
        left: main.$spacing-4;
        gap: main.$spacing-2;
        flex-direction: column;
    }

    .usedChip {
        background-color: main.$color-background-bluegray;
        color: main.$color-text-bluegray;
    }

    .newChip {
        background-color: main.$color-background-lightblue;
        color: main.$color-primary-500;
    }

    .discountedChip {
        color: main.$color-white;
        background-color: main.$color-discount-red;
    }
</style>
